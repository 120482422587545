import { differenceInDays } from 'date-fns';

export const numberOfDaysToRedirect = 14;

export const isCreationDateNew = (createdAtDate: string | undefined) => {
  if (!createdAtDate) {
    return false;
  }

  return (
    differenceInDays(Date.now(), new Date(createdAtDate)) <=
    numberOfDaysToRedirect
  );
};
