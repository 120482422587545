import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useOrgSlug } from '~/hooks/usePersistedOrgSlug';
import useRedirectToLaunchpad from '~/hooks/useRedirectToLaunchpad';

import composeRedirectUrl from './composeRedirectUrl';
import getIsEnterprise from './getIsEnterprise';

export const PipelinesOrProjects = () => {
  const orgSlug = useOrgSlug();
  const { query } = useRouter();
  const isEnterprise = getIsEnterprise();

  const { shouldRedirect: shouldRedirectToLaunchpad } =
    useRedirectToLaunchpad();

  useEffect(() => {
    const queryString = window.location.search;
    const redirectUrl = composeRedirectUrl(
      orgSlug,
      query,
      queryString,
      isEnterprise,
      shouldRedirectToLaunchpad,
    );
    window.location.replace(redirectUrl);
  }, [isEnterprise, orgSlug, query, shouldRedirectToLaunchpad]);

  return null;
};
