import * as url from 'url';

import React, { useContext } from 'react';

import { PageLoadingIndicator } from '@circleci/compass';

import ExceptionLogger from '~/backplane/appExceptionLogger';
import { useMe } from '~/hooks/useMe';
import ErrorPage from '~/pages/_error';
import { Me } from '~/utils/meMapper';

interface Props {
  children: React.ReactNode;
}

export const MeContextProvider: React.FC<Props> = ({ children }) => {
  // defining fetch inside of the hook so that it can be evaluated at call time.
  // this makes it much easier to mock out in our tests.
  const { me, error, loading } = useMe();
  const preloading = !me && !error; // For SSR, both of these are undefined

  if (preloading || loading) {
    return <PageLoadingIndicator containerHeight='100vh' />;
  }

  // On unauthorized 401 errors we navigate users to login page
  if (error?.message === 'Unauthorized') {
    const redirectURL = url.format({
      pathname: '/api/login',
      query: {
        'return-to': window.location.href,
      },
    });
    window.location.assign(redirectURL);

    // since window.location.href is async, we return a loading component
    // to prevent a screen flashing before the redirect
    return <PageLoadingIndicator containerHeight='100vh' />;
  }

  // For non unauthorized 401 errors we show the error page
  if (error) {
    ExceptionLogger.error('Error fetching Me', error);
    return (
      <ErrorPage
        error={error}
        statusCode={500}
      />
    );
  }

  return (
    /**
     * since we know that we are not loading, and that we have no errors
     * we can safely pass the Me object to the context and assert that
     * it is not undefined/null (this is the use of `!`)
     * this saves consumers from having to check for null (eg. me: me ? me : {})
     */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <MeContext.Provider value={me}>{children}</MeContext.Provider>
  );
};

const MeContext = React.createContext<Me>(null as any);

export const useMeContext = () => useContext(MeContext);

export default MeContext;
