import { format as urlFormat } from 'url';

import Cookies from 'js-cookie';
import { useQuery } from 'react-query';
import unfetch from 'unfetch';

import convertReactQueryErrorToError from '~/utils/convertReactQueryErrorToError';
import windowHasFetch from '~/utils/windowHasFetch';

import meMapper, { APIMe } from '../utils/meMapper';

const credentials = (apiKey: string | undefined) =>
  apiKey ? 'same-origin' : 'include';

const searchParams = (apiKey: string | undefined | null) => {
  const params = new URLSearchParams();
  if (apiKey) {
    params.append('circle-token', apiKey);
  }
  return params.toString();
};

export const useMe = () => {
  const apiKey =
    localStorage.getItem('CIRCLE_TOKEN') || Cookies.get('circle-token');
  const url = urlFormat({
    hostname: process.env.CIRCLECI_HOSTNAME || 'circleci.com',
    pathname: '/api/v1.1/me',
    protocol: 'https',
    search: searchParams(apiKey),
  });
  const fetch = windowHasFetch() ? window.fetch : unfetch;
  const {
    isLoading: loading,
    error,
    data,
  } = useQuery<APIMe>(
    url,
    async () => {
      const res = await fetch(url, {
        method: 'GET',
        credentials: credentials(apiKey) as any,
      });

      if (res.status === 401) {
        {
          throw new Error(`Unauthorized`);
        }
      }

      if (!res.ok) {
        throw new Error(`received non 2xx from ${url}`);
      }

      return await res.json();
    },
    { retry: false },
  );

  const convertedError = convertReactQueryErrorToError(error);

  return {
    me: data && meMapper(data),
    error: convertedError,
    isUnauthorized: convertedError?.message === `Unauthorized`,
    loading,
  };
};
