import * as MeContext from '~/contexts/MeContext';
import getIsEnterprise from '~/utils/getIsEnterprise';
import { Identity } from '~/utils/meMapper';

import { isCreationDateNew } from '../utils/createdAccountDate';

const useRedirectToLaunchpad = (): {
  shouldRedirect: boolean;
} => {
  const me = MeContext.useMeContext();

  const isRequiredVCSType = me?.identities?.some((i: Identity) => {
    return i.type === 'github' || i.type === 'bitbucket';
  });

  const shouldRedirect =
    !getIsEnterprise() && isRequiredVCSType && isCreationDateNew(me.createdAt);

  return {
    shouldRedirect,
  };
};

export default useRedirectToLaunchpad;
