import * as React from 'react';

import { useLocalStorage } from 'react-use';

type VCSType = 'bitbucket' | 'github';

interface Props {
  vcsType: VCSType;
  org: string;
}

const KEY = 'org-slug';

/** Check for a value set in web-ui */
const usePersistedOrgSlug = ({ vcsType, org }: Props) => {
  const [, setOrgSlug] = useLocalStorage(KEY, null);

  React.useEffect(() => {
    setOrgSlug(`${vcsType}/${org}`);
  }, [vcsType, org]);
};

export const useOrgSlug = (): string => {
  const [orgSlug] = useLocalStorage(KEY, '');
  return orgSlug;
};

export default usePersistedOrgSlug;
