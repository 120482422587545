const composeRedirectUrl = (
  orgSlug: string,
  query: object,
  queryString: string,
  isEnterprise: boolean,
  shouldRedirectToLaunchpad: boolean,
): string => {
  const shouldRedirectNonEnterpriseOrgToLaunchpad =
    shouldRedirectToLaunchpad && !isEnterprise;

  if (query['intent']) {
    return `/projects${queryString}`;
  }

  if (!orgSlug) {
    return '/projects';
  }

  if (shouldRedirectNonEnterpriseOrgToLaunchpad) {
    return `/launchpad/${orgSlug}/getting-started`;
  }

  return `/pipelines/${orgSlug}`;
};

export default composeRedirectUrl;
